@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";
@import 'react-toastify/dist/ReactToastify.css';
:root {
  --toastify-color-success: #d4edda;
  --toastify-color-error: #f8d7da;
  --toastify-text-color-success: #155724;
  --toastify-text-color-error: #721c24;
  --toastify-font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


@tailwind base;
@tailwind components;
@tailwind utilities;


.applyHtml {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }

  b, strong {
    @apply font-bold;
  }

  U {
    @apply underline;
  }

  a {
    text-decoration: underline;
    color: blue;
  }

}


@layer components{
  .borderedBtn{
    @apply text-sm border border-gray-600 rounded px-4 py-1 font-medium;
  }
  .primaryBtn{
    @apply text-sm border borderColor text-white rounded-md px-4 py-1 font-medium;
  }
  .commonBtn{
    @apply text-sm text-white rounded-md px-4 py-1 font-medium border border-transparent;
  }
}


input:disabled {
  background: #eee;
  opacity: 0.5;
}

.popupSelectBox .dropdown-content ul { height: 160px; overflow-y:auto; }
.popupSelectBox .dropdown-single-selected-label{ padding-left: 0 !important; }

.calcHeight {  min-height: calc(100vh - 250px); }
.mainContainer  {
  min-height: calc(100vh - 180px);
}
.whiteIcon svg path {
  stroke: #fff;
}

.hamburger .line{
  width: 24px;
  height: 2px;
  background-color: #f5f5f5;
  display: block;
  margin: 7px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* .hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
  background-color: #d5d5d5;
}

.hamburger.is-active .line:nth-child(2){
  opacity: 0;
  background-color: #d5d5d5;
}

.hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  -o-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
  background-color: #d5d5d5;
} */

.linkText {
  color: var(--color-primary);
}


.linkText:hover {
  color: #0F0F0F;
}


/* .selectCustomBox .select-item { width: 97% !important;  margin: 10px auto; padding: 16px; border-radius: 6px; } */


.bgColor {
  background-color:  var(--color-primary);
}

.commonBtn:hover,
.bgColor:hover {
  background: var(--color-primary-darker);
}

.borderColor {
  border-color:  var(--color-primary);
}

.font-18{
  font-size: 18px;
  color: #666666;
  margin-top: 12px;
}
.react-resizable-handle::after {
  border-right: 2px solid rgba(0, 0, 0, 0) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0) !important;
}

.react-resizable-handle {
  background: transparent !important;
}

body{
  font-size: 1rem;
}

html.fixedHeight, .fixedHeight #root, .fixedHeight body,
html.adminSite, .adminSite #root, .adminSite body {
  height: 100%;
}

html.fixedHeight {
  background-color: #f5f5f5;
}

.fixedHeight .fch100,
.adminSite .fch100 {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.minHeight500,
.clientSite .ag-theme-alpine {
  height: 500px;
}

.clientSite .h-250 .ag-theme-alpine {
  height: 200px;
}

.clientSite .ag-theme-alpine.ag-popup,
.clientSite .h-250 .ag-theme-alpine.ag-popup {
  height: auto;
}

.cartSidebar {
  right: -600px;
  bottom: 0;
}

.cartItemBox {
  height: calc(100% - 200px);
}


.blackOutBg .mainContainer::before{
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
}
.openMenuBody {
  position: fixed;
  width: 100%;
  height: 100%;
}
.openMenuBody .modalOverlay {
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
}

.downArrow {
  box-sizing: border-box;
  height: 6px;
  width: 6px;
  border-style: solid;
  border-color: white;
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
}

.ag-root-wrapper{
  border-radius: 8px;
}
.ag-header,
.ag-row {
  border-bottom-color: #e5e5e5;
}
.ag-root-wrapper {
  border-color: #e5e5e5;
}
.ag-paging-panel {
  border-top-color: #e5e5e5;
}

.iconbtn:hover svg path {
  fill: #b6311e;
}

.iconbtn.lock:hover svg path,
.iconbtn.trash:hover svg path {
  fill: none;
  stroke:#b6311e;
}

.flotech .iconbtn:hover svg path {
  fill: #772583;
}

.flotech .iconbtn.lock:hover svg path,
.flotech .iconbtn.trash:hover svg path {
  fill: none;
  stroke: #772583;
}

.ag-icon-menu::before {
  display: none !important;
}
.ag-icon-menu {
  cursor: pointer;
  background:  url(assets/images/search-icon.svg) no-repeat left top !important;
  width: 16px;
  height: 16px;
}

.item {
  flex-basis: 100px;
	flex-grow: 0;
	flex-shrink: 0;
}

.loadingBar {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  z-index: 9999;
}


.mainMenu .active svg path {
  stroke: #fff;
}

.ag-header-cell-text {
  color: #0F0F0F !important;
  font-size: 14px;
  font-weight: 500;
}

.headerBar .bottomOption,
.headerBar .dropdown-heading {
  background-color: #333;
}

.headerBar .dropdown-container {
  border: 1px solid #333 !important;
}
.headerBar .dropdown-heading .label {
  color: #d4d4d4;
  font-size: 14px;
}

.headerBar .dropdown-single-selected-label .label {
  white-space: nowrap;
  width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerBar .dropdown-content .search input:focus,
.headerBar .dropdown-content .panel-content {
  background-color: #333 !important;
}

.headerBar .dropdown-content .select-item.selected,
.headerBar .dropdown-content .select-item:hover {
  background-color: #111 !important;
}

.dropdown-content {
  z-index: 5 !important;
}

.dropdown-heading span,
.dropdown-content span{
  font-size: 13px;
}

.lineHeight20 {
  line-height: 20px;
}

.lineHeight22 {
   line-height: 22px;
}

.lineHeight48 {
  line-height: 48px;
}

.headerBar .dropdown-heading .dropdown-heading-value .dropdown-single-selected-label { padding: 0 !important; margin-top: 0 !important; }
.locationSelect .dropdown-heading .dropdown-heading-value .gray { margin-top: 8px !important; color: #000 !important; font-size: 13px; }

.locationSelect .dropdown-heading.customHeight, .locationSelect  .dropdown-heading.customHeight .dropdown-heading-value { height: auto; max-height: 100px; }
.locationSelect .dropdown-heading .dropdown-heading-value { align-items: flex-start !important; }

.ag-row.ag-row-first {
  height: 50px;
}


.customCheckbox {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #222;
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* Hide the browser's default checkbox */
.customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #b1b1b1;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.customCheckbox:hover input ~ .checkmark {
  background-color: #fff;
  border: 1px solid #8f8f8f;
}

/* When the checkbox is checked, add a blue background */
.customCheckbox input:checked ~ .checkmark {
  background-color: #AD1F23;
  border-color: #AD1F23;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customCheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customCheckbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* .cartItem:first-child div {
  border: none;
} */

.openCartMenu,
.openMenu {
  transition: 1s;
  right: 0 !important;
}

.closeMenu{
  transition: 1s;
  right: -400px !important;
}

.dashboard-main .ag-body-vertical-scroll { padding-bottom: 20px; background-color: #f1f1f1; }
.dashboard-main .ag-body-horizontal-scroll { padding-right: 20px; background-color: #f1f1f1; }
/* .react-grid-item > .react-resizable-handle.react-resizable-handle-se { background-color: #888 !important; } */
.orderListItem:last-child { border: none; }

.qtyStapper input {
  width: 100%;
}

.openMenu {
  transition: 1s;
  right: 0 !important;
}

.closeMenu{
  transition: 1s;
  right: -400px !important;
}
.modal .ReactModal__Overlay {
  background-color: rgb(15 15 15 / 0.7) !important;
}
.modal .ReactModal__Content {
 border: none !important;
  background: none !important;
}


div[aria-disabled="true"] {
  opacity: 0.5;
  background-color: #efefef !important;
}


.ql-container{ background-color: #fff; }


.arrowBtn { position:relative; }
.arrowBtn:before {
  position:absolute;
  top:12px;
  right: 20px;
  content:'';
  border: solid #a3a3a3;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrowBtn.up:before {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: 15px;
}


.elipsisSelectBox .dropdown-single-selected-label span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 230px;
}


.modalOverlay {
  display: none;
  background-color: #0F0F0F;
}

body.modelOpen {
  position: fixed;
  width: 100%;
}

.modelOpen .modalOverlay {
  display: block;
  opacity: 0.7;
}

.modelOpen .model {
  overflow-y: auto;
}


/*  Comomon Theme Progress Status Bar */

li:nth-child(1) .activeBgColor {
  background-color: #22C55E;
}

li:nth-child(2).activeBorder:before {
  border-color: #16A34A;
}
li:nth-child(2) .activeBgColor {
  background-color: #16A34A;
}


li:nth-child(3).activeBorder:before {
  border-color: #15803D;
}
li:nth-child(3) .activeBgColor {
  background-color: #15803D;
}


li:nth-child(3) .statusTitle {
  right:-25px;
}


li:nth-child(4).activeBorder:before {
  border-color: #166534;
}
li:nth-child(4) .activeBgColor {
  background-color: #166534;
}



.labelStyle label{
  font-weight: 500;
  margin-bottom: 4px;
}

.labelStyle .dropdown-content label {
  font-weight: 400 !important; ;
}

.quotesBar .child-option-list {
  max-height: 80px;
  overflow-y: auto;
  margin-top: 10px;
}

.addCustomerForm .text-gray-700 {
  color: #666 !important;
  font-weight: 400;
}


.visibleOptionModal .dropdown-content {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999 !important;
}

.visibleOptionModal .overflow-hidden {
  overflow: visible;
}


.visibleOptionModal .modalHeader {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.visibleOptionModal .modalMainContent {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.orderInfoModal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.orderInfoModal .modalMainContent {
  display: flex;
  flex-direction: column;
  height: calc( 100% - 40px );
}

.orderInfoModal .modalBody {
  display: flex;
  flex-direction: column;
  height: calc( 100% - 90px );
  overflow-y: auto;
}

.dashboardIcon{
  height: 37px;
}

.elipsisText .dropdown-heading-value {
  overflow: hidden;
}

.elipsisText .dropdown-single-selected-label .label{
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  white-space: nowrap;
}


.elipsisTextFixedWidth .dropdown-heading-value {
  overflow: hidden;
}

.elipsisTextFixedWidth .dropdown-single-selected-label .label{
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  width: 280px;
  white-space: nowrap;
}

.dropdown-heading .dropdown-heading-value .dropdown-single-selected-label {
  padding-left: 0 !important;
}

.fillBtnLink:hover svg path {
  fill: #000;
}

.strokeBtnLink:hover svg path {
  stroke: #000;
}


input { font-size: 14px; }

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #000;
}
textarea::-moz-placeholder,
input::-moz-placeholder { /* Firefox 19+ */
  font-size: 13px;
  color: #000;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder { /* IE 10+ */
  font-size: 13px;
  color: #000;
}

textarea:-moz-placeholder,
input:-moz-placeholder { /* Firefox 18- */
  font-size: 13px;
  color: #000;
}

label + textarea::-webkit-input-placeholder,
label + input::-webkit-input-placeholder,
label + div textarea::-webkit-input-placeholder,
label + div input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #aaa;
}

label + textarea::-webkit-input-placeholder,
label + input::-webkit-input-placeholder,
label + div textarea::-moz-placeholder,
label + div input::-moz-placeholder { /* Firefox 19+ */
  font-size: 13px;
  color: #aaa;
}

label + textarea::-webkit-input-placeholder,
label + input::-webkit-input-placeholder,
label + div textarea:-ms-input-placeholder,
label + div input:-ms-input-placeholder { /* IE 10+ */
  font-size: 13px;
  color: #aaa;
}

label + textarea::-webkit-input-placeholder,
label + input::-webkit-input-placeholder,
label + div textarea:-moz-placeholder,
label + div input:-moz-placeholder { /* Firefox 18- */
  font-size: 13px;
  color: #aaa;
}


.ql-container {
  min-height: 200px;
}

.autoHeightDropdown .dropdown-heading.customHeight {
  align-items: flex-start;
  height: auto;
  padding: 6px;
  max-height: 350px;
  overflow-y: auto;
}

.printHeader {
  display: none;
}

.locationSelect .dropdown-heading {
  min-height: 40px !important;
}

.addressDropdown .dropdown-heading-value .gray {
  margin-top: 8px;
}
.addressDropdown .dropdown-heading .dropdown-heading-value {
  align-items: flex-start;
}

.addressDropdown .dropdown-single-selected-label {
  min-height: 45px;
}

.borderNone input {
  border: none;
}

.ItemDescription, .CatDescription {
  box-shadow: #adadad 0.5px 0.5px 3px 1px;
}

// for change padding for mobile
.ag-header-cell-resize {
  padding:0px 10px;
}


@media only screen and (max-height: 699px) {
  .grayBox {
    height: auto !important;
  }
}


@media only screen and (max-height: 666px) {

  /* .cartItemBox {
    height: auto;
  } */

  .itemBoxContent {
    overflow-y: auto;
  }

  .cartItemBox  {
    overflow-y: unset;
  }
}

@media only screen and (max-width: 1107px) {
  .hide-button-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 1023px) {
.mainMenu {
  left: -500px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

}
.openMenu {
  left:0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
}

@media only screen and (max-width: 767px) {
  .headerBar .dropdown-single-selected-label .label {
    width: 10rem;
  }

  .calcHeight {
    min-height: 200px;
  }
}


@media only screen and (max-width: 639px) {
  .ag-paging-panel {
    display: block !important;
    text-align: center;
    padding: 10px;
    height: auto;
  }
  .dashboard-main .ag-paging-panel {
    display: none !important;
  }
  .noPaginationGrid .ag-paging-panel {
    display: none !important;
  }
  .ag-paging-page-summary-panel {
    justify-content: center;
    margin-top: 10px;
  }
  .cartSidebar {
    width: 100%;
  }
  .locationGridButton .borderedBtn {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media only screen and (max-width: 567px) {
  .checkoutBtn{
    width: 100%;
  }
}

@media only screen and (max-width: 413px) {
  .fourButton .borderedBtn {
      padding: 6px 10px;
  }
}



/*this css use for order history page  order again btn loader*/
.btnLoader {
  display: none;
}
.showBtnLoader .btnLoader {
  display: inline;
}



@media print {
  @page {
    size: A3 !important;
    margin: 0;
  }
  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    print-color-adjust: exact !important;  /*Firefox*/
  }
  header, footer, .buttonBar {
    display: none;
  }
  .printHeader {
    display: block;
  }
}

.fade-in {
  animation: fade-in 0.3s ease-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}




@media only screen and (min-width: 1536px) and (max-width: 1580px)  {
  .orderList .colItem {
    margin-bottom: 0.75rem;
  }
}

