.message-container {
    padding: 30px;
    max-width: 500px;
    margin: 40px auto;
    text-align: center;
    border-radius: 10px;
    border: 1px solid gray;
}

i {
    color: #469e46;
    font-size: 34px;
}

.message {
    font-size: 20px;
}