.dashboard-main {
  #gridMain,.ag-theme-alpine {
    height: 100% !important;
    &.ag-popup {
      height: auto !important;
    }
  }
  .resize-handler {
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 0 0 20px 20px;
    border-color: transparent transparent #888 transparent;
    transform: rotate(0deg);
    z-index: 999;
    padding: 0;
    &:before {
      content: '';
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      position: absolute;
      top:8px;
      left: -11px;
      transform: rotate(1deg);
      -webkit-transform: rotate(1deg);
    }
 }

}

.ag-theme-alpine {
  .btn{
    width: 110px;
  }
}

.btn{
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  padding: 4px 8px;
  outline: none;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  &.btn-completed {
    background-color: #F0FDF4;
    border-color: #4ADE80;
    color: #15803D;
    cursor: default;
  }
  &.btn-onhold {
    background-color: #eee;
    border-color: #b6b6b6;
    color: #666;
    cursor: default;
  }
  &.btn-canceled {
    background-color: #ffe9e2;
    border-color: #fa4e15;
    color: #fa4e15;
    cursor: default;
  }
  &.btn-processing {
    background-color: #FEFCE8;
    border-color: #FACC15;
    color: #CA8A04;
    cursor: default;
  }
  &.btn-dispatched {
    background-color: #CDF0FF;
    border-color: #0072A0;
    color: #004764;
    cursor: default;
  }
  &.btn-billing {
    background-color: blue;
  }
}
