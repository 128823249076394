
input[type="file"]::file-selector-button {
  position: absolute;
  border: none;
  height: 92%;
  left: 1px;
  top: 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
  color: inherit;
  opacity: 90%;
}

input[type="file"] {
  padding-left: 6.5rem;
  cursor: pointer;
}

